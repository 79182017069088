import React from "react";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete-account/AccountDeleteIcon.svg";
import { ReactComponent as SuccessIcon } from "../../assets/images/delete-account/AccountDeletionSuccess.svg";
import "../../assets/styles/components/Modal.scss";

const Modal = ({ isOpen, onClose, onConfirm, title, children, confirmText, cancelText, icon }) => {
  if (!isOpen) return null;

  // Choose icon dynamically
  const renderIcon = () => {
    if (icon === "delete") {
      return <DeleteIcon className="modal-icon delete-icon" />;
    } else if (icon === "success") {
      return (
        <>
          <SuccessIcon className="modal-icon success-icon" />
          <button className="close-modal-btn" onClick={onClose}>×</button>
        </>
      );
    }
    return null; // Default to no icon if none specified
  };

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <div className="modal-header">
          {renderIcon()}
          <div className="heading">{title}</div>
        </div>
        <div className="modal-content">{children}</div>
        <div className="modal-actions">
          {cancelText && <button onClick={onClose} className="modal-cancel">{cancelText}</button>}
          {confirmText && <button onClick={onConfirm} className="modal-confirm">{confirmText}</button>}
        </div>
      </div>
    </div>
  );
};

export default Modal;
