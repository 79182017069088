import React, { lazy, Suspense } from 'react';
import Skeleton from 'react-loading-skeleton';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomeLayout from './components/layouts/HomeLayout';
import Home from './pages/Home';
import ProductInfo from './pages/ProductInfo';
import ContactUs from './pages/ContactUs';
import NotFound from './pages/NotFound';
import Services from './pages/Services';
import AccountDeletion from './pages/AccountDelete';
import UpdatePassword from './pages/UpdatePassword';
import UpdateUsername from './pages/UpdateUsername';
import ResetPassword from './pages/ResetPassword';
import ResetUsername from './pages/ResetUsername';
import InvalidCode from './components/common/InvalidCode';
import CorporateResetPassword from './pages/CorporateResetPassword';
import CorporateUserDetails from './pages/CorporateUserDetails';
import PrivacyPolicy from './pages/PrivacyPolicy';
import JoinOrganisationLink from './pages/JoinOrganisationLink';
import 'react-loading-skeleton/dist/skeleton.css';

// Lazy load the About component
const About = lazy(() => import('./pages/About'));

function App() {
  return (
    <div className="App">
      <Router>
        <Suspense
          fallback={
            <div>
              {/* Skeleton loading UI for lazy-loaded About component */}
              <Skeleton height={40} width={300} style={{ marginBottom: 16 }} />
              <Skeleton height={20} count={2} width="80%" />
              <Skeleton height={200} width="100%" style={{ marginTop: 16 }} />
              <Skeleton height={150} width="100%" style={{ marginTop: 16 }} />
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<HomeLayout />}>
              <Route path="/" element={<Home />} />
              <Route path="/about-us" element={<About />} />
              <Route path="/delete-account/" element={<AccountDeletion />} />
              <Route path="/product-info" element={<ProductInfo />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/portfolio" element={<Services />} />
              <Route path="/update-password/:token/" element={<UpdatePassword />} />
              <Route path="/reset-password/:token/" element={<ResetPassword />} />
              <Route path="/update-username/:token/" element={<UpdateUsername />} />
              <Route path="/reset-username/:token/" element={<ResetUsername />} />
              <Route path="/corporate-create-password/:token/" element={<CorporateResetPassword />} />
              <Route path="/corporate-user-details" element={<CorporateUserDetails />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/invalid-code" element={<InvalidCode />} />
              <Route path="/join-organisation-link/:token/" element={<JoinOrganisationLink />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
