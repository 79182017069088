import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import AboutUs1 from '../../assets/images/portfolio/aboutus1.png';
import '../../assets/styles/pages/services/AboutUs.scss';

const AboutUs = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false, amount: 0.5 });

  return (
    <div className="about-us" ref={ref}>
      <motion.div
        className="about-us__image"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={isInView ? { opacity: 1, scale: 1 } : {}}
        transition={{ duration: 1.2 }}
      >
        <img src={AboutUs1} alt="About Antro PAI" />
      </motion.div>
      <div className="about-us__content">
        <div className='about-sub-heading'>WHO WE ARE</div>
        <motion.h2
          initial={{ opacity: 0, x: -50 }}
          animate={isInView ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 0.8 }}
        >
          About Us
        </motion.h2>
        <motion.p
          initial={{ opacity: 0, x: -50 }}
          animate={isInView ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 1 }}
        >
          At <span style={{ color: "#32A0EF"}}>Antro-PAI</span>, we're pioneers in cybersecurity and software development. Established in <span style={{ color: "#32A0EF"}}>2023</span>,
          we've rapidly become a beacon of innovation in Hyderabad's tech ecosystem. Our dual mission is
          to combat identity theft through state-of-the-art solutions and to deliver comprehensive software
          services that propel businesses into the digital future.
        </motion.p>
        <motion.ul
          className="about-us__list"
          initial={{ opacity: 0, y: 50 }}
          animate={isInView ? { opacity: 1, y: 0 } : {}}
          transition={{ delay: 0.2, duration: 1 }}
        >
          <div>Our team of <span style={{ color: "#32A0EF"}}>11-50</span> dedicated professionals brings together expertise in AI, software development,
            cybersecurity, and more, ensuring we stay at the cutting edge of technological advancements.
          </div>
        </motion.ul>
      </div>
    </div>
  );
};

export default AboutUs;
