import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { contactUsApi } from "../utils/apis";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { toast, ToastContainer } from 'react-toastify';
import ContactUsBg from "../assets/images/contact-us-bg.png";
import PhoneContactus from "../assets/images/contactUs/ic_round-phone.png";
import EmailContactus from "../assets/images/contactUs/ic_round-mail.png";
import AddressContactus from "../assets/images/contactUs/fluent_location-16-filled.png";
import LinkedinContactus from "../assets/images/contactUs/ri_linkedin-fill.png";
import MessageIcon from "../assets/images/contactUs/Sent Email.png";
import "../assets/styles/pages/ContactUs.scss";

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const handlePolicy = (e)=> {
    e.preventDefault()
    navigate('/privacy-policy')
  }
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .matches(/^[A-Za-z]+$/, "First name should only contain letters without spaces")
      .required("First name is required"),
    lastName: Yup.string()
      .matches(/^[A-Za-z]+$/, "Last name should only contain letters without spaces")
      .required("Last name is required"),
    email: Yup.string()
    .matches(emailRegex,"Invalid email address")
      .required("Email is required")
      .max(100, 'Email cannot be more than 100 characters'),
    phone: Yup.string()
      .matches(/^\+?[0-9]{7,15}$/, "Enter a valid phone number.  Only digits allowed, 7 to 15 characters.")
      .required("Phone number is required"),
    message: Yup.string().required("Message is required"),
    agree: Yup.boolean().oneOf([true], "You must accept the privacy policy"),
  });

  // Formik setup
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
      countryCode: "+91",
      agree: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const data = {
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          phone: values.phone,
          message: values.message,
        };

        const response = await contactUsApi(data);
        if (response.status === 200) {
          toast.success("Message sent successfully!");
          resetForm();
        } else {
          toast.error("Unable to submit the form. Please try again later.");
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again.");
      }
    },
  });

  return (
    <div className="contact-container">
      <ToastContainer />
      <div className="background-image-outer">
        {/* <img className="background-image" src={ContactUsBg} alt="Contact Us" loading="lazy"/> */}
        <div className="background-text">
          <div className="background-text-inner">
            <div className="heading">Get In Touch.</div>
            <div className="sub-heading">Let's Connect and Start the Conversation</div>
          </div>
        </div>
      </div>
      <div className="contact-container-inner">
        <div className="contact-info-main">
          <div className="contact-info">
            <div className="contact-info-heading">Contact Information</div>
            <div className="contact-info-sub-heading">Reach Us Anytime, We’re Here to Help</div>
            <div className="contact-info-phone">
              <span className="phone-img">
                <img src={PhoneContactus} alt="Phone" />
              </span>
              <div className="phone-number">
                <span>+91 70970 02759</span>
              </div>
            </div>
            <div className="contact-info-email">
              <span className="email-img">
                <img src={EmailContactus} alt="Email" />
              </span>
              <span className="text-email">connect@antrocorp.com</span>
            </div>
            <div className="contact-info-address">
              <span className="location-img">
                <img src={AddressContactus} alt="Address" />
              </span>
              <span className="text-address">
                H.No. 247/3RT, Ramakrishna Nivas, Sanjeeva Reddy Nagar, Hyderabad-500038
              </span>
            </div>
            <div className="social-media">
              <a href="https://www.linkedin.com/company/antro-pai-technologies-pvt-ltd/?originalSubdomain=in" aria-label="Linkedin">
                <img src={LinkedinContactus} alt="LinkedIn" />
              </a>
            </div>
          </div>
        </div>
        <div className="contact-form">
          <div className="contact-form-heading-icon">
            <div className="form-heading">Send us a Message</div>
            <img className="message-icon" src={MessageIcon} alt="Message Icon" />
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="main-form-group">
              <div className={`form-group ${formik.errors.firstName && formik.touched.firstName ? 'error-field' : ''}`}>
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  placeholder="First name"
                  maxLength={50}
                />
                {formik.errors.firstName && formik.touched.firstName && (
                  <div className="error">{formik.errors.firstName}</div>
                )}
              </div>
              <div className={`form-group ${formik.errors.lastName && formik.touched.lastName ? 'error-field' : ''}`}>
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  placeholder="Last name"
                  maxLength={50}
                />
                {formik.errors.lastName && formik.touched.lastName && (
                  <div className="error">{formik.errors.lastName}</div>
                )}
              </div>
            </div>

            <div className="main-form-group">
              <div className={`form-group ${formik.errors.email && formik.touched.email ? 'error-field' : ''}`}>
                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  placeholder="Email ID"
                  maxLength={100}
                />
                {formik.errors.email && formik.touched.email && (
                  <div className="error">{formik.errors.email}</div>
                )}
              </div>

              <div className={`form-group ${formik.errors.phone && formik.touched.phone ? 'error-field' : ''}`}>
                <label htmlFor="phone">Phone Number</label>
                <div className="phone-input-wrapper">
                  <select
                    name="countryCode"
                    id="countryCode"
                    value={formik.values.countryCode}
                    onChange={formik.handleChange}
                  >
                    <option value="+91">IND</option>
                    <option value="+1">USA</option>
                    <option value="+44">UK</option>
                    <option value="+61">AUS</option>
                    <option value="+81">JAP</option>
                  </select>
                  <div className="line-bet"></div>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    placeholder="Phone number"
                    maxLength={15}
                  />
                </div>
                {formik.errors.phone && formik.touched.phone && (
                    <div className="error">{formik.errors.phone}</div>
                  )}
              </div>
            </div>
            <div className="main-form-group">
              <div className={`form-group ${formik.errors.message && formik.touched.message ? 'error-field' : ''}`}>
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  placeholder="Tell us what we can help with..."
                 
                />
                {formik.errors.message && formik.touched.message && (
                  <div className="error">{formik.errors.message}</div>
                )}
              </div>
            </div>
            <div className="main-form-group">
              <div className="form-group checkbox-group">
                <input
                  type="checkbox"
                  id="agree"
                  name="agree"
                  checked={formik.values.agree}
                  onChange={formik.handleChange}
                  // required
                />
                <label htmlFor="agree">
                  I'd like to receive more information about the company. I
                  understand and agree to the <a onClick={handlePolicy}>Privacy Policy</a>.
                </label>
                
              </div>
              

              
            </div>
            {formik.errors.agree && formik.touched.agree && (
                  <div className="error">{formik.errors.agree}</div>
                )}
              <div className="contact-btn">
                <button type="submit" className="submit-btn">Send Message</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
