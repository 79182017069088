// utils/validationHelpers.js

export const validateIdentifier = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Validates email format
    const phoneRegex = /^\+[1-9]\d{10,14}$/; // Phone number format: + (country code) followed by 10-14 digits
    const usernameRegex = /^[a-zA-Z0-9_]{3,30}$/; // Username format: Alphanumeric, 3-30 chars
  
    if (emailRegex.test(value)) return null;
    if (phoneRegex.test(value)) return null;
    if (usernameRegex.test(value)) return null;
  
    return "Invalid input. Please provide a valid email, phone number (+91), or username (3-30 alphanumeric characters).";
  };
  
  export const handleInputChange = (value) => {
    let input = value;
  
    // Restrict input based on the starting "+91" for phone numbers
    if (input.startsWith("+91")) {
      // Allow only "+91" followed by up to 10 digits
      input = input.replace(/[^\d+]/g, ""); // Remove non-numeric characters except "+"
      if (input.length > 13) input = input.slice(0, 13); // Limit to "+91" + 10 digits
    } else {
      // Allow standard input for emails and usernames
      input = input.trim();
    }
  
    return input;
  };
  