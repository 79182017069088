// src/pages/updateUserDetails.js

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchResetLinkData, updateUserDetails } from '../utils/apis';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import AntroLogo from "../assets/images/antrologo.png";
import PadlockIcon from "../assets/images/Padlock.svg";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../assets/styles/pages/UpdatePassword.scss';

const UpdatePassword = () => {
  const { token } = useParams();
  const navigate = useNavigate(); // Initialize useNavigate
  const [secretCode, setSecretCode] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [touched, setTouched] = useState({});

  useEffect(() => {
    if (!token) {
      window.location.href = '/invalid-code';
    } else {
      setSecretCode(token);
      checkLink(token);
    }
  }, [token]);

  const checkLink = async (token) => {
    try {
      const data_type = "update_password";
      const result = await fetchResetLinkData(data_type, token);
      if (!result.success && result.errors.link_verified === false) {
        window.location.href = '/invalid-code';
      }
    } catch (error) {
      console.log('Error checking link:', error);
      window.location.href = '/invalid-code';
    }
  };

  const togglePasswordVisibility = (field) => {
    switch (field) {
      case 'oldPassword':
        setShowOldPassword(!showOldPassword);
        break;
      case 'newPassword':
        setShowNewPassword(!showNewPassword);
        break;
      case 'confirmPassword':
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  const validateOldPassword = (password) => {
    const errors = [];
    if (password.length === 0) errors.push('Old password is required.');
    return errors;
  };

  const validatePassword = (password) => {
    const errors = [];
    if (password.length === 0) return errors;
    if (password.length < 8) errors.push('Password must be at least 8 characters long.');
    if (password.length > 15) errors.push('Password must not be more than 15 characters long.');
    if (!/[A-Z]/.test(password)) errors.push('Password must contain at least one uppercase letter.');
    if (!/[a-z]/.test(password)) errors.push('Password must contain at least one lowercase letter.');
    if (!/[0-9]/.test(password)) errors.push('Password must contain at least one number.');
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) errors.push('Password must contain at least one special character.');
    return errors;
  };

  const checkFormValidity = () => {
    const oldPasswordErrors = validateOldPassword(oldPassword);
    const passwordErrors = validatePassword(newPassword);
    const confirmPasswordErrors = [];
    
    if (oldPassword === newPassword) {
      passwordErrors.push('Old and new passwords cannot be the same.');
    }
    
    if (newPassword !== confirmPassword) {
      confirmPasswordErrors.push('Passwords do not match.');
    }

    setErrors({
      oldPassword: oldPasswordErrors,
      newPassword: passwordErrors,
      confirmPassword: confirmPasswordErrors,
    });

    setIsSubmitEnabled(
      oldPasswordErrors.length === 0 &&
      passwordErrors.length === 0 &&
      confirmPasswordErrors.length === 0
    );
  };

  useEffect(() => {
    checkFormValidity();
  }, [oldPassword, newPassword, confirmPassword]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const oldPasswordErrors = validateOldPassword(oldPassword);
    const passwordErrors = validatePassword(newPassword);
    const confirmPasswordErrors = [];

    if (oldPassword === newPassword) {
      passwordErrors.push('Old and new passwords cannot be the same.');
    }
    if (newPassword !== confirmPassword) {
      confirmPasswordErrors.push('Passwords do not match.');
    }

    setErrors({
      oldPassword: oldPasswordErrors,
      newPassword: passwordErrors,
      confirmPassword: confirmPasswordErrors,
    });

    if (oldPasswordErrors.length > 0 || passwordErrors.length > 0 || confirmPasswordErrors.length > 0) {
      return;
    }

    try {
      const data_type = "update_password";
      const result = await updateUserDetails(data_type, secretCode, newPassword, oldPassword);

      if (result.success) {
        toast.success('Your password has been updated successfully.');
        
        // Show redirection message
        document.querySelector('.container').innerHTML = `
          <h2>Password Updated</h2>
          <p>Your password has been successfully updated. Redirecting to home page in 5 seconds...</p>
        `;

        // Redirect to home page after 5 seconds
        setTimeout(() => {
          navigate("/"); // Redirect to home page
        }, 5000);
      } else {
        toast.error(result.message || 'An error occurred. Please try again.');
      }
    } catch (error) {
      toast.error('Your password was not updated successfully. Please try again.');
      console.log('API Error:', error);
    }
  };

  return (
    <div className='update-password-layout'>
      <div className="main-update-password">
        <ToastContainer />
        <div className="header">
          <img src={AntroLogo} alt="Antro Logo" className="logo" />
        </div>
        <img src={PadlockIcon} alt="Padlock Icon" className="lock-icon" />
        <div className="container">
          <h2>Update Password</h2>
          <form id="newPasswordForm" className="form-corporate-newpassword" onSubmit={handleSubmit}>
            <div className={`input-wrapper${errors.oldPassword && errors.oldPassword.length > 0 ? ' error' : ''}`}>
              <input
                className="password-input"
                type={showOldPassword ? 'text' : 'password'}
                id="oldPassword"
                maxLength="15"
                placeholder="Old Password"
                value={oldPassword}
                onChange={(e) => {
                  setOldPassword(e.target.value);
                  setTouched({ ...touched, oldPassword: true }); // Mark input as touched
                }}
              />
              <span
                className="toggle-password"
                onClick={() => togglePasswordVisibility('oldPassword')}>
                <FontAwesomeIcon icon={showOldPassword ? faEye : faEyeSlash} className="eye-icon" />
              </span>
            </div>
            {touched.oldPassword && errors.oldPassword && errors.oldPassword.map((error, index) => (
              <div key={index} className="error-message">{error}</div>
            ))}
            <div className={`input-wrapper${errors.newPassword && errors.newPassword.length > 0 ? ' error' : ''}`}>
              <input
                className="password-input"
                type={showNewPassword ? 'text' : 'password'}
                id="newPassword"
                maxLength="15"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                  setTouched({ ...touched, newPassword: true }); // Mark input as touched
                }}
              />
              <span
                className="toggle-password"
                onClick={() => togglePasswordVisibility('newPassword')}>
                <FontAwesomeIcon icon={showNewPassword ? faEye : faEyeSlash} className="eye-icon" />
              </span>
            </div>
            {touched.newPassword && errors.newPassword && errors.newPassword.map((error, index) => (
              <div key={index} className="error-message">{error}</div>
            ))}
            <div className={`input-wrapper${errors.confirmPassword && errors.confirmPassword.length > 0 ? ' error' : ''}`}>
              <input
                className="password-input"
                type={showConfirmPassword ? 'text' : 'password'}
                id="confirmPassword"
                maxLength="15"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setTouched({ ...touched, confirmPassword: true }); // Mark input as touched
                }}
              />
              <span
                className="toggle-password"
                onClick={() => togglePasswordVisibility('confirmPassword')}>
                <FontAwesomeIcon icon={showConfirmPassword ? faEye : faEyeSlash} className="eye-icon" />
              </span>
            </div>
            {touched.confirmPassword && errors.confirmPassword && errors.confirmPassword.map((error, index) => (
              <div key={index} className="error-message">{error}</div>
            ))}
            <div className='main-submit-btn'>
            <button type="submit" id="submitButton" disabled={!isSubmitEnabled}>Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdatePassword;
